var utilObj = {
    //判断访问终端
    browser: {
        versions: function () {
            var u = navigator.userAgent, app = navigator.appVersion;
            return {
                trident: u.indexOf('Trident') > -1, //IE内核
                presto: u.indexOf('Presto') > -1, //opera内核
                webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,//火狐内核
                mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
                ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
                iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                iPad: u.indexOf('iPad') > -1, //是否iPad
                webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
                weixin: u.toLowerCase().indexOf('micromessenger') > -1, //是否微信
                qq: u.match(/\sQQ/i) == " qq" //是否QQ
            };
        }(),
        language: (navigator.browserLanguage || navigator.language).toLowerCase(),
        lteIE11: function () {
            var u = navigator.userAgent;
            var status = (u.toLowerCase().indexOf("msie") != -1 && parseInt(u.toLowerCase().match(/msie ([\d.]+)/)[1]) <= 11) || (u.indexOf('Trident') > -1 && u.indexOf('rv:11') > -1);
            return status;
        },
        gteIE9: function () {
            var u = navigator.userAgent;
            var status = u.toLowerCase().indexOf("msie") != -1 && u.toLowerCase().match(/msie ([\d.]+)/)[1] >= 9;
            return status;
        }
    }
}

var isInBrowser = false;

// 微信分享
var $ = window.jQuery;
(function initShareData () {
    var ctx = '/commercial';
    var imgLink = window.location.origin + "/commercial/assets/admin/commercial_logo_angle.jpg";
    var title = "蚂蚁商用车";
    var desc = document.title;
    var u = navigator.userAgent;
    var wechatBrowser = u.toLowerCase().indexOf('micromessenger') > -1;

    if (wechatBrowser) {
        $.ajax({
            url: ctx + '/rest/wechat/getWechatBridgeParams',
            data: {
                url: window.location.href,
            },
            success: function (res) {
                var result = res.data;

                var shareData = {
                    title: title,
                    desc: desc,
                    link: window.location.href,
                    imgUrl: imgLink,
                    type: 'link',
                    dataUrl: '',
                    success: function () {
                    },
                    cancel: function () {
                    }
                }

                wx.config({
                    debug: false, // 开启调试模式。
                    appId: result.appId, // 必填，公众号的唯一标识
                    timestamp: result.timestamp, // 必填，生成签名的时间戳
                    nonceStr: result.nonceStr, // 必填，生成签名的随机串
                    signature: result.signature,// 必填，签名
                    jsApiList: [
                        'onMenuShareAppMessage',
                        'onMenuShareTimeline',
                        'onMenuShareQQ',
                        'onMenuShareWeibo',
                        'onMenuShareQZone',
                    ] // 必填，需要使用的JS接口列表
                });

                wx.ready(function () {
                    // 分享到朋友圈
                    var timelineData = JSON.parse(JSON.stringify(shareData));
                    timelineData.title = desc;
                    wx.onMenuShareTimeline(timelineData);
                    // 分享给微信好友
                    wx.onMenuShareAppMessage(shareData);
                    // 分享到QQ好友
                    wx.onMenuShareQQ(shareData);
                    // 分享到微博
                    wx.onMenuShareWeibo(shareData);
                    // 分享到QQ空间
                    wx.onMenuShareQZone(shareData);
                })
            }
        })
    }
}())

var androidLink;

$.ajax({
    url: "/commercial/appVersion/queryLastAndroidAppUrl",
    success: function (res) {
        if (res.code == 200) {
            androidLink = res.data.downloadLink;
        }
    }
})

var mHeader = Vue.extend({
    template: "#downloadHeader",
    data: function () {
        return {
            hide: false,
            version: utilObj.browser.versions,
            loading: false,
            showWechatTip: false,
        }
    },
    computed: {
        iosWeb: function () {
            return this.version.ios;
        },
        inWeChat: function () {
            return this.version.weixin;
        },
        androidWeb: function () {
            return this.version.android;
        },
        schemeURL: function () {
            if (this.iosWeb) {
                return "CareagleCV://";
            } else if (this.androidWeb) {
                return "antcar://splash";
            }
        },
        downloadURL: function () {
            if (this.iosWeb) {
                return "https://itunes.apple.com/cn/app/%E8%9A%82%E8%9A%81%E5%95%86%E7%94%A8%E8%BD%A6/id1344143700?mt=8";
            } else if (this.androidWeb) {
                return androidLink;
            }
        },
        paramsData: function () {
            // 待联调
            return "";
        },
    },
    methods: {
        closeMHeader: function () {
            this.hide = true;
        },
        openOrDownloadApp: function () {
            if (this.inWeChat) {
                this.showWechatTip = true;
                return;
            }
            if (this.loading) {
                return;
            }
            this.loading = true;
            var vm = this;
            var limit_num = 50;
            var openTime = +new Date();
            var timer = setInterval(function () {
                if (limit_num > 0) {
                    limit_num--;
                } else {
                    vm.loading = false;
                    // 加了100ms基准误差，防止打开app的同时，又跳转到下载页
                    if ((new Date()) - openTime < 1100) {
                        window.location.href = vm.downloadURL;
                    }
                    clearTimeout(timer);
                }
            }, 20);
        },
    }
})

var app = new Vue({
    el: "#app",
    data: function () {
        return {
            inBrowser: isInBrowser
        }
    },
    components: {
        'm-header': mHeader,
    }
})